import React from 'react';
import logo from './assets/driver-report-logos.jpeg'
import './App.css';
import { Button, TextField } from '@mui/material';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Protecting our roads is our priority
        </p>
        <p>
        <div style={{marginBottom: '.5em'}}> File a report </div>
        <TextField id="outlined-basic" label="Vehicle Id" variant="outlined"/>
        <div>
        <Button variant="contained" style={{marginTop: '.5em'}}>Go</Button>
        </div>
        </p>
      </header>
    </div>
  );
}

export default App;
